import Moment from 'moment-timezone'
import presenters from '@presenters'

import {
  camelCaseify,
  formAdd,
  snakeCaseify,
  sortByAttribute,
  roundMoneyUp,
} from '~/utils'
import { LegacyLocalesToStandardLocales } from '~/utils/timezone'
import services from '@services'
const { OrderService } = services

const discountKinds = [
  'cancellation',
  'discount',
  'hungry_guarantee',
  'marketing',
  'tasting',
  'sales code',
  'partnership',
  'budget',
]

export const pResponseOrders = (json) => {
  const orders = camelCaseify(json)
  orders.forEach((order) => {
    order.chefName = order.chefsString
    order.dateStr = Moment(order.clientSetUpTime).format('MM-DD-YY @ h:mma')
    order.dateMoment = Moment(order.clientSetUpTime)
    order.total = parseFloat(order.total).toFixed(2)
  })

  return orders
}

export const pResponseOrdersforBulkGeneration = (json) => {
  const orders = camelCaseify(json)
  orders.forEach((order) => {
    order.chefName = order.chefsString
    order.dateStr = Moment(order.clientSetUpTime).format('MM-DD-YY @ h:mma')
    order.dateMoment = Moment(order.clientSetUpTime)
    order.total = parseFloat(order.total).toFixed(2)
    if (order.chefs.length > 0) {
      order.chefs.forEach((c) => {
        c.orderMenuItems = (order.orderMenuItems || []).filter(
          (i) => i.chefId === c.id,
        )
        c.orderServiceItems = (order.orderServiceItems || []).filter(
          (i) => i.chefId === c.id,
        )
        c.orderVirtualItems = (order.orderVirtualItems || []).filter(
          (i) => i.chefId === c.id,
        )
        c.orderVirtualKits = (order.orderVirtualKits || []).filter(
          (i) => i.chefId === c.id,
        )
        c.orderSnackPacks = (order.orderSnackPacks || []).filter(
          (i) => i.chefId === c.id,
        )
        c.customOrderMenuItems = []
        c.customOrderServiceItems = []
        c.customOrderVirtualItems = []
        c.chefNote = (order.chefOrderInstructions || []).find(
          (i) => i.chefId === c.id,
        )
      })
    }
  })

  return orders
}

export const pResponseChefOrder = (json) => {
  return camelCaseify(json)
}

export const pResponseOrder = (json) => {
  const order = camelCaseify(json)
  order.setUpStyle = order.setUpStyle || 'None Selected'

  order.eventDate = order.eventDate && Moment(order.eventDate)
  order.clientSetUpTime = order.clientSetUpTime && Moment(order.clientSetUpTime)
  order.clientDoNotArriveBeforeTime =
    order.clientDoNotArriveBeforeTime &&
    Moment(order.clientDoNotArriveBeforeTime)
  order.hqArrivalTime = order.hqArrivalTime && Moment(order.hqArrivalTime)
  order.hqDepartureTime = order.hqDepartureTime && Moment(order.hqDepartureTime)
  order.kitchenArrivalTime =
    order.kitchenArrivalTime && Moment(order.kitchenArrivalTime)
  order.kitchenDepartureTime =
    order.kitchenDepartureTime && Moment(order.kitchenDepartureTime)
  order.clientArrivalTime =
    order.clientArrivalTime && Moment(order.clientArrivalTime)
  order.chargeDate = order.chargeDate && Moment(order.chargeDate)
  order.clientOrderReminderLastSent =
    order.clientOrderReminderLastSent &&
    Moment(order.clientOrderReminderLastSent)

  order.eventDateStr = order.clientSetUpTime.format(
    'dddd, MMMM Do YYYY, h:mm:ss a',
  )
  order.dateStr =
    order.clientSetUpTime && order.clientSetUpTime.format('MMM. DD, YYYY h:mmA')
  order.hqArrivalTimeStr =
    order.hqArrivalTime && order.hqArrivalTime.format('h:mm A')
  order.hqDepartureTimeStr =
    order.hqDepartureTime && order.hqDepartureTime.format('h:mm A')
  order.kitchenArrivalTimeStr =
    order.kitchenArrivalTime && order.kitchenArrivalTime.format('h:mm A')
  order.kitchenDepartureTimeStr =
    order.kitchenDepartureTime && order.kitchenDepartureTime.format('h:mm A')
  order.clientArrivalTimeStr =
    order.clientArrivalTime && order.clientArrivalTime.format('h:mm A')
  order.clientSetUpTimeStr =
    order.clientSetUpTime && order.clientSetUpTime.format('h:mm A')
  order.chargeDateStr =
    order.chargeDate && order.chargeDate.format('MMM. DD, YYYY h:mmA')
  order.clientOrderReminderLastSentStr =
    order.clientOrderReminderLastSent &&
    order.clientOrderReminderLastSent.format('dddd, MMMM Do YYYY, h:mm a')
  order.isEventStr = order.isEvent ? 'Yes' : 'No'

  // discounts
  const cleanedDiscounts = []
  order.statuses = order.orderStatuses || []
  order.discounts = order.orderDiscounts || []
  order.discounts.forEach((d) => {
    d.value = parseFloat(d.value)
    if (discountKinds.includes(d.kind)) {
      // admin discounts
      let desc
      if (d.isPercentage) {
        desc = `${Math.trunc(d.value * 100)}% off`
      } else {
        desc = `$${d.value.toFixed(2)} off`
      }

      d.description = `${desc} ${d.code != null ? `(${d.code})` : ''}`
    } else {
      // mp discounts
      const type = d.kind.replace(/_/gi, ' ').toUpperCase()
      d.description = `${type} ${d.code != null ? `(${d.code})` : ''}`
    }

    if (!discountKinds.includes(d.kind)) {
      cleanedDiscounts.push(d)
    } else {
      order.discount = d
    }
  })
  order.discounts = cleanedDiscounts
  order.totalDiscount = parseFloat(order.totalDiscount || 0).toFixed(2)

  // service fees
  order.deliveryFee = parseFloat(order.deliveryFee || 0).toFixed(2)
  order.cleanupFee = parseFloat(order.cleanupFee || 0).toFixed(2)
  order.staffingFee = parseFloat(order.staffingFee || 0).toFixed(2)
  order.numberOfStaff = parseFloat(order.numberOfStaff || 0).toFixed(2)
  order.staffingHours = parseFloat(order.staffingHours || 0).toFixed(2)
  order.carbonNeutralContribution = parseFloat(
    order.carbonNeutralContribution || 0,
  ).toFixed(2)

  const pm = order.paymentMethod
  order.paymentMethodStr =
    pm &&
    `${pm.cardholderName || 'Name'} - ${pm.cardType || 'Card Type'} - Last 4${
      pm.last4 && ' *' + pm.last4
    } - ${pm.expirationDate || 'Exp Date'}`

  order.contactStr =
    (order.contact && order.contact.formattedDescription) || 'None Selected'
  order.invoiceContactStr =
    (order.invoiceContact && order.invoiceContact.formattedDescription) ||
    'None Selected'
  order.receiptContactStr =
    (order.receiptContact && order.receiptContact.formattedDescription) ||
    'None Selected'

  order.paidAmount = parseFloat(order.paidAmount || 0).toFixed(2)
  order.subtotal = parseFloat(order.subtotal || 0).toFixed(2)
  order.tax = parseFloat(order.tax || 0).toFixed(2)
  order.tip = parseFloat(order.tip || 0).toFixed(2)
  order.total = parseFloat(order.total || 0).toFixed(2)
  order.chefAmount = parseFloat(order.chefAmount || 0.0).toFixed(2)

  order.allPackagingStr = (order.allPackaging || []).join('\n') || 'None'
  order.servingUtensilsStr =
    order.servingUtensilsDescription.join(', ') || 'None'
  order.servicesOrderItemsStr =
    (order.servicesOrderItems || []).join('\n') || 'None'
  order.servicesAddOnsStr = (order.servicesAddOns || []).join('\n') || 'None'

  order.orderItems = (order.orderItems || []).filter((o) => o)
  sortByAttribute(order.orderItems, 'displayOrder')
  order.addOnChefs = (order.addOnChefs || []).filter((c) => c)

  const ae = order.accountExecutive
  if (ae) {
    ae.name = [ae.firstName, ae.lastName].filter((o) => o).join(' ')
  }

  const cc = order.cateringCaptain
  if (cc) {
    cc.name = [cc.firstName, cc.lastName].filter((o) => o).join(' ')
  }

  order.audits &&
    order.audits.forEach((a) => {
      a.createdAt = Moment(a.createdAt).format('MM-DD-YY @ h:mma')
      a.auditedChanges = a.auditedChanges && JSON.stringify(a.auditedChanges)
    })
  order.transactions &&
    order.transactions.forEach((t) => {
      t.createdAt = Moment(t.createdAt).format('MM-DD-YY @ h:mma')
      t.amount = parseFloat(t.amount || 0).toFixed(2)
      t.serviceFeeAmount = parseFloat(t.serviceFeeAmount || 0).toFixed(2)
    })

  const requiredActions = order.requiredActions || []
  order.needAlertClient = requiredActions.includes('Alert Client')
  order.needServiceDetails = requiredActions.includes('Service Details')
  order.needToOrderSurvey = requiredActions.includes('Order Survey')
  order.needAlertChef = order.chefAlerts.length > 0

  return order
}

export const pResponseServiceOrder = (json) => {
  const order = camelCaseify(json)
  if (order && order.routes) {
    order.routes.forEach((route) => {
      route.stops.forEach((stop) => {
        if (stop.chefId) {
          stop.chef = order.chefs.find((c) => c.id === stop.chefId)
        }
        switch (stop.type) {
          case 'chef':
            stop.label = stop.chef.name
            break
          case 'client':
            stop.label = 'Client'
            break
          case 'hub':
            stop.label = 'Depot'
            break
          default:
            break
        }
      })
    })
  }

  return order
}

export const organizeChildOrderItems = (orderItems) => {
  let parentItems = {}
  const childItems = []
  orderItems.forEach((item) => {
    if (item.menuItem && item.menuItem.parentMenuItemId) {
      item.category = item.menuItem.subMenuItemCategory
      childItems.push(item)
    } else {
      item.childItems = []
      parentItems[item.menuItemId] = item
    }
  })
  childItems.forEach((item) => {
    const parent = parentItems[item.menuItem.parentMenuItemId]
    if (parent) {
      parent.childItems.push(item)
      sortByAttribute(parent.childItems, 'displayOrder')
    } else {
      item.childItems = []
      parentItems[item.menuItemId] = item
    }
  })

  parentItems = Object.values(parentItems)
  sortByAttribute(parentItems, 'displayOrder')

  return parentItems
}

export const pResponseEditOrder = ({ orderableJson, calculateDiscounts }) => {
  const order = camelCaseify(orderableJson)
  order.serviceNotes = order.servicesInstructions
  order.setUpStyle = order.setUpStyle || ''
  order.chargeDate = order.chargeDate && Moment(order.chargeDate)
  order.clientSetUpTime = order.clientSetUpTime && Moment(order.clientSetUpTime)
  order.clientDoNotArriveBeforeTime =
    order.clientDoNotArriveBeforeTime &&
    Moment(order.clientDoNotArriveBeforeTime)
  order.cateringContact = order.contact
  order.chefAmount = parseFloat(order.chefAmount || 0)
  order.chefStatuses = order.chefStatuses || {}
  order.chefPayouts = order.chefPayouts || {}
  order.adjustedChefPayouts = Object.keys(order.chefPayouts).map((k) => ({
    id: k,
    payout: order.chefPayouts[k],
  }))
  order.deliveryFee = parseFloat(order.deliveryFee || 0)
  order.deliveryFeePercent = order.deliveryFeePercent
    ? parseFloat(order.deliveryFeePercent)
    : null
  order.deliveryFeeLimit = order.deliveryFeeLimit
    ? parseFloat(order.deliveryFeeLimit)
    : null
  order.cleanupFee = parseFloat(order.cleanupFee || 0)
  order.staffingFee = parseFloat(order.staffingFee || 0)
  order.staffingRate = parseFloat(order.staffingRate || 0)
  order.serviceFee = parseFloat(order.serviceFee || 0)
  order.carbonNeutralContribution = parseFloat(
    order.carbonNeutralContribution || 0,
  )
  order.predictedServiceCosts = parseFloat(order.predictedServiceCosts || 0)
  order.paidAmount = parseFloat(order.paidAmount || 0)
  order.subtotal = parseFloat(order.subtotal || 0)
  order.tax = parseFloat(order.tax || 0)
  order.tip = parseFloat(order.tip || 0)
  order.total = parseFloat(order.total || 0)
  order.isCopy = false
  let lastStatus
  ;(order.orderStatuses || []).forEach((status) => {
    if (
      !lastStatus ||
      Moment(status.updatedAt).isAfter(Moment(lastStatus.updatedAt))
    ) {
      lastStatus = status
    }
  })
  order.status = lastStatus ? lastStatus.code : ''

  const cleanedDiscounts = []
  // Pull out all the mp discounts with promo codes, then set the main discount to the sales code from admin
  order.orderDiscounts.forEach((d) => {
    if (!discountKinds.includes(d.kind)) {
      const value = d.value == null ? null : parseFloat(d.value)
      cleanedDiscounts.push({
        ...d,
        amount: d.isPercentage ? value * 100 : value,
        value,
        id: d.id,
        type: d.isPercentage ? 'percent' : 'flat', // is this unused?
        kind: d.isPercentage ? 'percentage' : 'flat', // need to include this to calculate discounts correctly in order service
        code: d.code || d.kind,
      })
    }
    if (d.kind === 'free_delivery') {
      order.freeDeliveryPromoCode = true
    }
  })
  if ((order.orderDiscounts || []).length > 0) {
    const d = order.orderDiscounts.find((discount) =>
      discountKinds.includes(discount.kind),
    )
    if (d) {
      const discountAmount = d.isPercentage
        ? parseFloat(d.value) * 100
        : parseFloat(d.value)
      const discountType = d.isPercentage ? 'percent' : 'flat'
      order.discount = {
        amount: discountAmount,
        id: d.id,
        type: discountType,
        reason: d.reason,
        kind: d.kind,
      }

      order.discountKind = d.kind
      order.discountReason = d.reason
      order.discountAmount = discountAmount
      order.discountType = discountType
    }
  }
  order.orderDiscounts = cleanedDiscounts

  order.originalMenuItemIds = order.orderMenuItems.map((i) => i.id)
  order.originalServiceItemIds = order.orderServiceItems.map((i) => i.id)
  order.originalVirtualItemIds = order.orderVirtualItems.map((i) => i.id)
  order.originalVirtualKitIds = order.orderVirtualKits.map((i) => i.id)
  order.originalSnackPackIds = order.orderSnackPacks.map((i) => i.id)

  // Clean order menu items
  order.orderMenuItems.forEach((i) => {
    i.chefPrice = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    i.retailPrice = parseFloat(i.retailPrice)
    if (!i.displayOrder) {
      const displayOrders = order.orderMenuItems.map((i) => i.displayOrder || 0)
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    } else {
      i.displayOrder = parseFloat(i.displayOrder)
    }
  })
  order.orderMenuItems = organizeChildOrderItems(order.orderMenuItems)
  sortByAttribute(order.orderMenuItems, 'displayOrder')

  // Clean order service items
  order.orderServiceItems.forEach((i) => {
    i.cost = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    if (!i.displayOrder) {
      const displayOrders = order.orderServiceItems.map(
        (i) => i.displayOrder || 0,
      )
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    } else {
      i.displayOrder = parseFloat(i.displayOrder)
    }
  })
  sortByAttribute(order.orderServiceItems, 'displayOrder')

  // Clean order virtual items
  order.orderVirtualItems.forEach((i) => {
    i.cost = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    if (!i.displayOrder) {
      const displayOrders = order.orderVirtualItems.map(
        (i) => i.displayOrder || 0,
      )
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    } else {
      i.displayOrder = parseFloat(i.displayOrder)
    }
  })
  sortByAttribute(order.orderVirtualItems, 'displayOrder')

  order.orderVirtualKits.forEach((i) => {
    i.cost = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    if (!i.displayOrder) {
      const displayOrders = order.orderVirtualKits.map(
        (i) => i.displayOrder || 0,
      )
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    } else {
      i.displayOrder = parseFloat(i.displayOrder)
    }
  })
  sortByAttribute(order.orderVirtualKits, 'displayOrder')

  order.orderSnackPacks.forEach((i) => {
    i.cost = parseFloat(i.cost)
    i.price = parseFloat(i.price)
    if (!i.displayOrder) {
      const displayOrders = order.orderSnackPacks.map(
        (i) => i.displayOrder || 0,
      )
      const maxDisplayOrder = Math.max(...displayOrders)
      i.displayOrder = maxDisplayOrder + 1
    } else {
      i.displayOrder = parseFloat(i.displayOrder)
    }
  })
  sortByAttribute(order.orderSnackPacks, 'displayOrder')

  // Group all order items by chef
  if (order.chefs.length > 0) {
    order.chefs.forEach((c) => {
      c.orderMenuItems = order.orderMenuItems.filter((i) => i.chefId === c.id)
      c.orderServiceItems = order.orderServiceItems.filter(
        (i) => i.chefId === c.id,
      )
      c.orderVirtualItems = order.orderVirtualItems.filter(
        (i) => i.chefId === c.id,
      )
      c.orderVirtualKits = order.orderVirtualKits.filter(
        (i) => i.chefId === c.id,
      )
      c.orderSnackPacks = order.orderSnackPacks.filter((i) => i.chefId === c.id)
      c.customOrderMenuItems = []
      c.customOrderServiceItems = []
      c.customOrderVirtualItems = []
      c.chefNote = order.chefOrderInstructions.find((i) => i.chefId === c.id)
    })
  }

  const {
    clientSetUpTime,
    createdAt,
    discountAmount,
    discountType,
    discounts,
    subtotal,
    tax,
    tip,
    serviceFee,
    total,
    taxRates,
    deliveryTaxRates,
    servicesTaxRates,
    alcoholTaxRates,
    dropoffAddress,
    isGratuityOverride,
    isStaffingFeeOverride,
    accountSettings,
    dinerProfileSettings,
  } = order

  // calc initial balances
  const discountValue = calculateDiscounts({
    discountAmount,
    discountType,
    discounts,
    subtotal,
  })
  order.initialBalances = {
    subtotal,
    serviceFee,
    tax,
    tip,
    discounts: discountValue,
    total,
  }
  // calc taxRecord for tax calc in total section
  order.taxRecord = {
    tax,
    zipcode: dropoffAddress ? dropoffAddress.zip : undefined,
    taxRates,
    deliveryTaxRates,
    servicesTaxRates,
    alcoholTaxRates,
  }

  // calc and apply order settings
  const orderSettings = OrderService.calcOrderSettings({
    createdAt,
    clientSetUpTime,
    accountSettings,
    dinerProfileSettings,
  })
  const settingsUpdates = OrderService.updateOrderFromSettings({
    orderSettings,
    isGratuityOverride,
    isStaffingFeeOverride,
  })

  Object.keys(settingsUpdates).forEach((key) => {
    order[key] = settingsUpdates[key]
  })

  return order
}

export const pResponseOrderError = (e) => {
  let errors = {}

  const messageMap = {
    orderType: {
      messages: {
        'is not included in the list': 'Select a meal type',
      },
    },
    headCount: {
      messages: {
        'is not a number': 'Invalid number',
      },
    },
    dropoffAddress: {
      messages: {
        'orders must have a dropoff address': 'Select a delivery address',
      },
    },
    pickupAddress: {
      target: 'chef',
      messages: {
        'orders must have a pickup address': 'Select a chef',
      },
    },
    contact: {
      target: 'cateringContact',
      messages: {
        "can't be blank": 'Select a catering contact',
      },
    },
    invoiceContact: {
      messages: {
        "can't be blank": 'Select an invoice contact',
      },
    },
    receiptContact: {
      messages: {
        "can't be blank": 'Select a receipt contact',
      },
    },
    'Charge Date': {
      messages: {
        'cannot be in the past': 'Select a charge date in the in the future',
      },
    },
  }

  if (e.response && e.response.data) {
    if (e.response.data.errors) {
      const { errors: errors_ } = camelCaseify(e.response.data)
      for (let k in errors_) {
        if (Array.isArray(errors_[k])) {
          const mappedMsg = messageMap[k]
          let msg = errors_[k][0]
          k = (mappedMsg && mappedMsg.target) || k
          msg = (mappedMsg && mappedMsg.messages[msg]) || msg
          errors[k] = msg
        }
      }
    } else if (e.response.data.error) {
      errors = camelCaseify(e.response.data.error)
    }
  } else if (e.message) {
    let errorMessage = e.message
    if (/timeout/.test(errorMessage)) {
      errorMessage = 'Request timed out. Please try again'
    }
    errors = errorMessage
  } else {
    errors = e
  }

  return {
    errors,
    errorMessage: `errorMessage: ${JSON.stringify(errors)}`,
  }
}

export const pResponseCateringCaptains = (json) => {
  const captains = camelCaseify(json)

  captains.forEach((c) => {
    c.name = [c.firstName, c.lastName].filter((o) => o).join(' ')
  })

  return captains
}

export const pResponseOrderOptions = (json) => {
  return camelCaseify(json)
}

export const pResponseAccountExecutives = (json) => {
  const executives = camelCaseify(json)
  executives.forEach((e) => {
    e.name = [e.firstName, e.lastName].filter((o) => o).join(' ')
  })

  return executives
}

export const combineDateTime = (date, time) => {
  const newTime = Moment(date)
  time = Moment(time)
  newTime.hour(time.hour())
  newTime.minute(time.minute())
  newTime.second(0)
  newTime.millisecond(0)

  return newTime
}

export const addPossibleNullDate = (date) => {
  if (date === null) {
    return date
  }

  return date.toDate()
}

export const createOrderVirtualItem = (i, displayOrder) => {
  const item = {}
  formAdd(i, item, 'id', 'id')
  formAdd(i, item, 'invoiceable', 'invoiceable')
  // formAdd( i, item, 'displayOrder', 'display_order', v => v )
  formAdd(i, item, 'quantity', 'quantity')
  formAdd(i, item, 'cost', 'cost', (v) => v.toFixed(2))
  formAdd(i, item, 'price', 'price', (v) => v.toFixed(2))
  formAdd(i, item, 'virtualItemId', 'virtual_item_id')
  displayOrder[0] = displayOrder[0] + 1
  item.display_order = displayOrder[0]

  if (!i.virtualItemId) {
    item.virtual_item_attributes = { is_approved: true, is_enabled: true }
    formAdd(i, item.virtual_item_attributes, 'chefId', 'chef_id')
    formAdd(i, item.virtual_item_attributes, 'name', 'name')
    formAdd(i, item.virtual_item_attributes, 'cost', 'cost', (v) =>
      v.toFixed(2),
    )
    formAdd(i, item.virtual_item_attributes, 'price', 'price', (v) =>
      v.toFixed(2),
    )
  }

  return item
}

export const createOrderVirtualKit = (i, displayOrder) => {
  const item = {}
  formAdd(i, item, 'id', 'id')
  formAdd(i, item, 'invoiceable', 'invoiceable')
  // formAdd( i, item, 'displayOrder', 'display_order', v => v )
  formAdd(i, item, 'quantity', 'quantity')
  formAdd(i, item, 'cost', 'cost', (v) => v.toFixed(2))
  formAdd(i, item, 'price', 'price', (v) => v.toFixed(2))
  formAdd(i, item, 'virtualKitId', 'virtual_kit_id')
  displayOrder[0] = displayOrder[0] + 1
  item.display_order = displayOrder[0]

  if (!i.virtualKitId) {
    item.virtual_kit_attributes = {
      is_approved: true,
      is_enabled: true,
      virtual_item_packaging_attributes: {
        order_suppliable_type: 'Packaging',
      },
    }
    formAdd(i, item.virtual_kit_attributes, 'chefId', 'chef_id')
    formAdd(i, item.virtual_kit_attributes, 'name', 'name')
    formAdd(i, item.virtual_kit_attributes, 'cost', 'cost', (v) => v.toFixed(2))
    formAdd(i, item.virtual_kit_attributes, 'price', 'price', (v) =>
      v.toFixed(2),
    )
    formAdd(
      i,
      item.virtual_kit_attributes,
      'containsAlcohol',
      'contains_alcohol',
    )

    formAdd(
      i,
      item.virtual_kit_attributes.virtual_item_packaging_attributes,
      'packagingId',
      'order_suppliable_id',
    )
  }

  return item
}

export const createOrderSnackPack = (i, displayOrder) => {
  const item = {}
  formAdd(i, item, 'id', 'id')
  formAdd(i, item, 'invoiceable', 'invoiceable')
  // formAdd( i, item, 'displayOrder', 'display_order', v => v )
  formAdd(i, item, 'quantity', 'quantity')
  formAdd(i, item, 'cost', 'cost', (v) => v.toFixed(2))
  formAdd(i, item, 'price', 'price', (v) => v.toFixed(2))
  formAdd(i, item, 'snackPackId', 'snack_pack_id')
  displayOrder[0] = displayOrder[0] + 1
  item.display_order = displayOrder[0]

  if (!i.snackPackId) {
    item.snack_pack_attributes = {
      is_approved: true,
      is_enabled: true,
    }
    formAdd(i, item.virtual_kit_attributes, 'chefId', 'chef_id')
    formAdd(i, item.virtual_kit_attributes, 'name', 'name')
    formAdd(i, item.virtual_kit_attributes, 'cost', 'cost', (v) => v.toFixed(2))
    formAdd(i, item.virtual_kit_attributes, 'price', 'price', (v) =>
      v.toFixed(2),
    )
  }

  return item
}

export const createOrderServiceItem = (i, displayOrder) => {
  const item = {}
  formAdd(i, item, 'id', 'id')
  // formAdd( i, item, 'displayOrder', 'display_order', v => v )
  formAdd(i, item, 'quantity', 'quantity')
  formAdd(i, item, 'invoiceable', 'invoiceable')
  if (i.rateQuantity) {
    formAdd(i, item, 'rateQuantity', 'rate_quantity')
  }
  formAdd(i, item, 'cost', 'cost', (v) => v.toFixed(2))
  formAdd(i, item, 'price', 'price', (v) => v.toFixed(2))
  formAdd(i, item, 'serviceItemId', 'service_item_id')
  formAdd(i, item, 'serviceItemCategory', 'service_item_category')
  displayOrder[0] = displayOrder[0] + 1
  item.display_order = displayOrder[0]

  if (!i.serviceItemId) {
    item.service_item_attributes = { is_approved: true, is_enabled: true }
    formAdd(i, item.service_item_attributes, 'chefId', 'chef_id')
    formAdd(i, item.service_item_attributes, 'name', 'name')
    formAdd(i, item.service_item_attributes, 'cost', 'cost', (v) =>
      v.toFixed(2),
    )
    formAdd(i, item.service_item_attributes, 'price', 'price', (v) =>
      v.toFixed(2),
    )
    formAdd(i, item.service_item_attributes, 'description', 'description')
    formAdd(
      i,
      item.service_item_attributes,
      'serviceItemCategory',
      'service_item_category',
    )
  }

  return item
}

export const createOrderMenuItems = (
  i,
  displayOrder,
  results = [],
  isChild = false,
  creatorId = '',
) => {
  const item = {}
  formAdd(i, item, 'id', 'id')
  formAdd(i, item, 'chefPrice', 'cost', (v) => v.toFixed(2))
  if (!isChild && i.retailPrice != null) {
    formAdd(i, item, 'retailPrice', 'retail_price', (v) =>
      parseFloat(v).toFixed(2),
    )
  }
  formAdd(i, item, 'conceptsMenuItemId', 'concepts_menu_item_id')
  formAdd(i, item, 'menuItemId', 'menu_item_id')
  formAdd(i, item, 'price', 'price', (v) => v.toFixed(2))
  formAdd(i, item, 'quantity', 'quantity')
  formAdd(i, item, 'invoiceable', 'invoiceable')
  const increment = isChild ? 0.01 : 1
  displayOrder[0] = displayOrder[0] + increment
  item.display_order = displayOrder[0]

  if (!i.menuItemId) {
    item.menu_item_attributes = {
      creator_id: creatorId,
      menu_item_packaging_attributes: {
        order_suppliable_type: 'Packaging',
      },
      menu_item_serving_utensil_attributes: {
        order_suppliable_type: 'ServingUtensil',
      },
    }
    formAdd(i, item.menu_item_attributes, 'chefPrice', 'cost', (v) =>
      v.toFixed(2),
    )
    formAdd(i, item.menu_item_attributes, 'price', 'price', (v) => v.toFixed(2))
    if (!isChild) {
      formAdd(
        i,
        item.menu_item_attributes,
        'retailPrice',
        'retail_price',
        (v) => v.toFixed(2),
      )
    }
    formAdd(i, item.menu_item_attributes, 'chefId', 'chef_id')
    formAdd(i, item.menu_item_attributes, 'description', 'description')
    formAdd(i, item.menu_item_attributes, 'ingredients', 'tags_ingredient_list')
    formAdd(i, item.menu_item_attributes, 'mealType', 'meal_type')
    formAdd(i, item.menu_item_attributes, 'name', 'name')
    formAdd(
      i,
      item.menu_item_attributes.menu_item_packaging_attributes,
      'packagingId',
      'order_suppliable_id',
    )
    formAdd(
      i,
      item.menu_item_attributes.menu_item_packaging_attributes,
      'servingsPerPkg',
      'servings_per_pkg',
    )
    formAdd(
      i,
      item.menu_item_attributes.menu_item_serving_utensil_attributes,
      'servingUtensilId',
      'order_suppliable_id',
    )
    formAdd(
      i,
      item.menu_item_attributes,
      'tagsDietaryPreferenceList',
      'tags_dietary_preference_list',
    )
  }

  results.push(item)

  if (i.childItems) {
    const childDisplayOrder = [displayOrder[0]]
    i.childItems.forEach((c) =>
      createOrderMenuItems(c, childDisplayOrder, results, true),
    )
  }

  return results
}

export const pRequestUpdateOrderPaymentMethod = (data) => {
  const req = {}
  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'account.id', 'account_id')
  formAdd(data, req, 'paymentMethod.id', 'payment_method_id')

  return req
}

export const createChefOrderInstruction = (
  chefNote,
  orderableType,
  orderableId = null,
) => {
  return {
    id: chefNote.id,
    user_id: chefNote.chefId,
    words: chefNote.words,
    chef_instructable_id: orderableId,
    chef_instructable_type: orderableType,
    hungry_delivery_required: chefNote.hungryDeliveryRequired,
  }
}

export const pRequestUpdateOrder = (data) => {
  const req = { ...snakeCaseify(data.taxRates) }
  const { referredBy } = data
  if (referredBy && referredBy.contactId) {
    const { id, contactId, _destroy } = referredBy
    req.referral_attributes = {
      id,
      referrable_id: data.id,
      referrable_type: 'Order',
      contact_id: contactId,
      is_active: true,
      _destroy,
    }
  }
  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'hubspotProposalId', 'existing_hubspot_id')
  formAdd(data, req, 'account.id', 'account_id')
  formAdd(data, req, 'accountExecutive.id', 'account_executive_id')
  formAdd(data, req, 'clientReportingProgramId', 'client_reporting_program_id')
  formAdd(
    data,
    req,
    'removeClientReportingProgramReason',
    'remove_client_reporting_program_reason',
  )
  formAdd(data, req, 'beoLink', 'beo_link')
  req.lead_generator_type = 'User'
  formAdd(data, req, 'conceptId', 'concept_id')
  formAdd(data, req, 'dinerProfileId', 'diner_profile_id')
  formAdd(data, req, 'dropoffAddress.id', 'dropoff_address_id')
  formAdd(data, req, 'pickupAddress.id', 'pickup_address_id')
  formAdd(data, req, 'billingAddress.id', 'billing_address_id')
  // special consideration for payment method to be removed to avoid charging wrong client
  req.payment_method_id = (data.paymentMethod && data.paymentMethod.id) || null

  formAdd(data, req, 'cateringContact.id', 'contact_id')
  formAdd(data, req, 'invoiceContact.id', 'invoice_contact_id')
  formAdd(data, req, 'receiptContact.id', 'receipt_contact_id')
  formAdd(data, req, 'clientSetUpTime', 'client_set_up_time', (v) => v.toDate())
  formAdd(
    data,
    req,
    'clientDoNotArriveBeforeTime',
    'client_do_not_arrive_before_time',
    (v) => (v ? combineDateTime(data.clientSetUpTime, v) : null),
  )
  formAdd(data, req, 'clientArrivalTime', 'client_arrival_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'hqArrivalTime', 'hq_arrival_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'hqDepartureTime', 'hq_departure_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'kitchenArrivalTime', 'kitchen_arrival_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'kitchenDepartureTime', 'kitchen_departure_time', (v) =>
    combineDateTime(data.clientSetUpTime, v),
  )
  formAdd(data, req, 'chargeDate', 'charge_date', (v) => addPossibleNullDate(v))
  formAdd(data, req, 'serviceNotes', 'services_instructions')
  formAdd(data, req, 'accountSettings', 'account_settings', (v) =>
    snakeCaseify(v),
  )
  formAdd(data, req, 'dinerProfileSettings', 'diner_profile_settings', (v) =>
    snakeCaseify(v),
  )
  formAdd(data, req, 'deliveryInstructions', 'delivery_instructions')
  formAdd(data, req, 'orderType', 'order_type')
  formAdd(data, req, 'isEvent', 'is_event')
  formAdd(data, req, 'purchaseOrderNumber', 'purchase_order_number')
  formAdd(data, req, 'setUpInstructions', 'set_up_instructions')
  formAdd(data, req, 'setUpStyle', 'set_up_style')
  formAdd(data, req, 'serviceType', 'service_type')
  formAdd(data, req, 'headCount', 'head_count')
  formAdd(data, req, 'predictedServiceCosts', 'predicted_service_costs', (v) =>
    (v || 0).toFixed(2),
  )
  formAdd(data, req, 'serviceFee', 'service_fee', (v) => (v || 0).toFixed(2))
  formAdd(data, req, 'deliveryFee', 'delivery_fee', (v) => (v || 0).toFixed(2))
  formAdd(data, req, 'deliveryFeePercent', 'delivery_fee_percent', (v) =>
    v ? v.toFixed(2) : null,
  )
  formAdd(data, req, 'deliveryFeeLimit', 'delivery_fee_limit', (v) =>
    v ? v.toFixed(2) : null,
  )
  formAdd(data, req, 'cleanupFee', 'cleanup_fee', (v) => (v || 0).toFixed(2))
  formAdd(data, req, 'staffingFee', 'staffing_fee', (v) => (v || 0).toFixed(2))
  formAdd(data, req, 'numberOfStaff', 'number_of_staff', (v) =>
    (v || 0).toFixed(2),
  )
  formAdd(data, req, 'staffingHours', 'staffing_hours', (v) =>
    (v || 0).toFixed(2),
  )
  formAdd(data, req, 'staffingRate', 'staffing_rate', (v) =>
    (v || 0).toFixed(2),
  )
  formAdd(data, req, 'isDeliveryFeeOverride', 'is_delivery_fee_override')
  formAdd(data, req, 'isGratuityOverride', 'is_gratuity_override')
  formAdd(data, req, 'isStaffingFeeOverride', 'is_staffing_fee_override')
  formAdd(data, req, 'carbonNeutral', 'carbon_neutral')
  formAdd(
    data,
    req,
    'carbonNeutralContribution',
    'carbon_neutral_contribution',
    (v) => (v || 0).toFixed(2),
  )
  formAdd(data, req, 'needsCleanup', 'needs_cleanup')
  formAdd(data, req, 'needsStaffing', 'needs_staffing')
  formAdd(data, req, 'chefAmount', 'chef_amount', (v) => (v || 0).toFixed(2))
  formAdd(data, req, 'subtotal', 'subtotal', (v) => (v || 0).toFixed(2)) // before delivery, tax, discounts => just order and service items
  if (
    (data.dropoffAddress && data.dropoffAddress.zip) ||
    (data.orderType === 'VCX' && data.billingAddress && data.billingAddress.zip)
  ) {
    formAdd(data, req, 'tax', 'tax', (v) => (v || 0).toFixed(2))
    formAdd(data, req, 'servicesTaxRates', 'services_tax_rates') // not a permitted attribute in API
    formAdd(data, req, 'deliveryTaxRates', 'delivery_tax_rates')
    formAdd(data, req, 'taxRates', 'tax_rates')
    formAdd(data, req, 'alcoholTaxRates', 'alcohol_tax_rates')
  }
  req.tip = (data.tip || 0).toFixed(2)
  formAdd(data, req, 'total', 'total', (v) => (v || 0).toFixed(2))
  req.serving_utensil_sets = 1

  if (data.discount && Object.keys(data.discount).length > 0) {
    const is_percentage = data.discount.type === 'percent'
    const discount = {
      is_percentage,
      pre_total: false,
      value: is_percentage ? data.discount.amount / 100 : data.discount.amount,
      reason: data.discount.reason,
      kind: data.discount.kind,
    }
    if (data.discount.id) {
      discount.id = data.discount.id
    }
    if (data.discount._destroy) {
      discount._destroy = true
    }

    req.order_discounts_attributes = [discount]
  }

  if (data.discounts) {
    if (!req.order_discounts_attributes) {
      req.order_discounts_attributes = []
    }
    data.discounts.forEach((discount) => {
      if (discount._destroy) {
        req.order_discounts_attributes.push({ id: discount.id, _destroy: true })
      }
    })
  }

  /* Items */

  req.order_menu_items_attributes = []
  req.order_service_items_attributes = []
  req.order_virtual_items_attributes = []
  req.order_virtual_kits_attributes = []
  req.order_snack_packs_attributes = []

  const displayOrder = [0] // array for pass by reference hack
  const addOrderMenuItemToReq = (orderMenuItem) => {
    req.order_menu_items_attributes.push(
      ...createOrderMenuItems(
        orderMenuItem,
        displayOrder,
        [],
        false,
        data.creatorId,
      ),
    )
  }
  const addOrderServiceItemToReq = (orderServiceItem) => {
    req.order_service_items_attributes.push(
      createOrderServiceItem(orderServiceItem, displayOrder),
    )
  }
  const addOrderVirtualItemToReq = (orderVirtualItem) => {
    req.order_virtual_items_attributes.push(
      createOrderVirtualItem(orderVirtualItem, displayOrder),
    )
  }
  const addOrderVirtualKitToReq = (orderVirtualKit) => {
    req.order_virtual_kits_attributes.push(
      createOrderVirtualKit(orderVirtualKit, displayOrder),
    )
  }
  const addOrderSnackPackToReq = (orderSnackPack) => {
    req.order_snack_packs_attributes.push(
      createOrderSnackPack(orderSnackPack, displayOrder),
    )
  }

  data.chefs.forEach((chef) => {
    chef.orderMenuItems.forEach(addOrderMenuItemToReq)
    chef.customOrderMenuItems.forEach(addOrderMenuItemToReq)

    chef.orderServiceItems.forEach(addOrderServiceItemToReq)
    chef.customOrderServiceItems.forEach(addOrderServiceItemToReq)

    chef.orderVirtualItems.forEach(addOrderVirtualItemToReq)
    chef.customOrderVirtualItems.forEach(addOrderVirtualItemToReq)

    chef.orderVirtualKits.forEach(addOrderVirtualKitToReq)

    chef.orderSnackPacks.forEach(addOrderSnackPackToReq)
  })

  // find deleted items
  const originalMenuItemIds = data.originalMenuItemIds.slice()
  const originalServiceItemIds = data.originalServiceItemIds.slice()
  const originalVirtualItemIds = data.originalVirtualItemIds.slice()
  const originalVirtualKitIds = data.originalVirtualKitIds.slice()
  const originalSnackPackIds = data.originalSnackPackIds.slice()
  const removePresentIds = (originalItemIds) => (item) => {
    if (item.id) {
      const idx = originalItemIds.indexOf(item.id)
      if (idx !== -1) {
        originalItemIds.splice(idx, 1)
      }
    }
  }
  req.order_menu_items_attributes.forEach(removePresentIds(originalMenuItemIds))
  req.order_service_items_attributes.forEach(
    removePresentIds(originalServiceItemIds),
  )
  req.order_virtual_items_attributes.forEach(
    removePresentIds(originalVirtualItemIds),
  )
  req.order_virtual_kits_attributes.forEach(
    removePresentIds(originalVirtualKitIds),
  )
  req.order_snack_packs_attributes.forEach(
    removePresentIds(originalSnackPackIds),
  )
  originalMenuItemIds.forEach((id) => {
    req.order_menu_items_attributes.push({ id, _destroy: true })
  })
  originalServiceItemIds.forEach((id) => {
    req.order_service_items_attributes.push({ id, _destroy: true })
  })
  originalVirtualItemIds.forEach((id) => {
    req.order_virtual_items_attributes.push({ id, _destroy: true })
  })
  originalVirtualKitIds.forEach((id) => {
    req.order_virtual_kits_attributes.push({ id, _destroy: true })
  })
  originalSnackPackIds.forEach((id) => {
    req.order_snack_packs_attributes.push({ id, _destroy: true })
  })

  // chef notes handled separately for order updates
  const chefNotes = []
  data.chefs.forEach((chef) => {
    if (chef.chefNote) {
      chefNotes.push(
        createChefOrderInstruction(chef.chefNote, 'Order', data.id),
      )
    }
  })
  req.chef_order_instructions_attributes = chefNotes
  data.chefPayouts &&
    Object.keys(data.chefPayouts).forEach((k) => {
      if (!data.chefs.find((c) => c.id === k)) {
        delete data.chefPayouts[k]
      }
    })
  req.chef_payouts = data.chefPayouts
  req.chef_statuses = snakeCaseify(data.chefStatuses)

  // service cost
  req.order_service_cost_attributes = snakeCaseify(data.orderServiceCost)

  return req
}

export const pRequestUpdateChefPayouts = (chefPayouts) => {
  const req = {}
  req.chef_payouts = chefPayouts.map((c) => {
    return {
      chef_id: c.chefId,
      chef_name: c.chefName,
      payout: c.payout,
    }
  })

  req.chef_payouts = {}
  chefPayouts.forEach((c) => {
    req.chef_payouts[`${c.chefId}`] = c.payout
  })

  return req
}

export const pRequestUpdateOrderServiceInfo = (data) => {
  const req = {}

  formAdd(data, req, 'id', 'id')
  formAdd(data, req, 'selectedCateringCaptain.id', 'catering_captain_id')
  formAdd(data, req, 'serviceDepotId', 'service_depot_id')

  if (data.clientSetUpTime) {
    req.client_set_up_time = combineDateTime(
      data.clientSetUpTime,
      data.clientSetUpTime,
    )
    if (data.clientArrivalTime) {
      req.client_arrival_time = combineDateTime(
        data.clientSetUpTime,
        data.clientArrivalTime,
      )
    }
    if (data.hqDepartureTime) {
      req.hq_departure_time = combineDateTime(
        data.clientSetUpTime,
        data.hqDepartureTime,
      )
    }
    if (data.hqArrivalTime) {
      req.hq_arrival_time = combineDateTime(
        data.clientSetUpTime,
        data.hqArrivalTime,
      )
    }
    if (data.kitchenArrivalTime) {
      req.kitchen_arrival_time = combineDateTime(
        data.clientSetUpTime,
        data.kitchenArrivalTime,
      )
    }
    if (data.kitchenDepartureTime) {
      req.kitchen_departure_time = combineDateTime(
        data.clientSetUpTime,
        data.kitchenDepartureTime,
      )
    }
  }

  return req
}

export const pRequestChefOrders = (
  calendarStartDate,
  calendarEndDate,
  withoutItems = false,
) => {
  return { start: calendarStartDate, end: calendarEndDate, withoutItems }
}

export const pRequestChefOrder = (chefId, orderType) => {
  return { chefId, orderType }
}

export const pRequestTimeSlots = ({ date, time }) => {
  // find available chefs
  date = Moment(date)
  time = Moment(time)
  const day = date.day()
  const week = date.startOf('week').format('MM/DD/YYYY')
  time = time.hour() + time.minute() / 60

  return {
    day,
    time,
    week,
  }
}

/*
 *
 *   ADHOC ACCOUNT INFO SAVING ON NEW ORDER
 *
 */

export const pRequestSaveAdhocAccount = (data) => {
  if (!data.newAccount) {
    return {}
  }

  return {
    account: {
      name: data.newAccount.name,
    },
  }
}

export const pRequestSaveAdhocAddress = ({ account, address }) => {
  const { buildingInstructions, line1, line2, city, state, zip } = address

  return {
    account: {
      id: account.id,
      addresses_attributes: [
        {
          building_instructions: buildingInstructions,
          line1,
          line2,
          city,
          state,
          zip,
        },
      ],
    },
  }
}

export const pRequestSaveAdhocContact = ({ account, contact }) => {
  const {
    id,
    firstName,
    lastName,
    phoneNumber,
    email,
    title,
    purchaserTaxStatus,
  } = contact

  const data = { account: { id: account.id } }
  const contactData = {
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    email,
    title,
    purchaser_tax_status: purchaserTaxStatus,
  }
  if (id) {
    contactData.id = id
    data.account['account_contacts_attributes'] = [
      {
        contact_id: id,
        account_id: account.id,
        contact_attributes: contactData,
      },
    ]
  } else {
    data.account['contacts_attributes'] = [contactData]
  }

  return data
}

export const pRequestSaveAdhocPaymentMethod = ({ account, paymentMethod }) => {
  const { cardholderName, nonce } = paymentMethod

  return {
    account: {
      id: account.id,
      payment_methods_attributes: [
        {
          cardholder_name: cardholderName,
          nonce,
        },
      ],
    },
  }
}

export const pRequestOrdersFilter = (filters) => {
  let filtersCopy = { ...filters }
  for (const property in filtersCopy) {
    if (filtersCopy[property] == undefined) {
      delete filtersCopy[property]
    } else if (Moment.isMoment(filtersCopy[property])) {
      filtersCopy[property] = filtersCopy[property].format()
    }
  }
  filtersCopy = snakeCaseify(filtersCopy)

  const { search = '' } = filtersCopy
  const m = search.match(/(\d{2})[-/.](\d{2})[-/.](\d{4})/)
  if (search && m) {
    filtersCopy.search = `client_set_up_time:${m[3]}-${m[1]}-${m[2]}`
  }

  return filtersCopy
}

export const pRequestPayOrderWithExternal = (
  order_id,
  source,
  amount,
  is_refund,
  send_receipt,
) => {
  let json_source = ''
  switch (source) {
    case 'Manual Credit Card':
      json_source = 'braintree'
      break
    case 'ACH':
      json_source = 'ach'
      break
    case 'Check':
      json_source = 'check'
      break
    case 'Braintree':
      json_source = 'automated'
      break
  }
  if (is_refund) {
    json_source = 'automated'
  }

  return {
    order_id,
    payment_source: json_source,
    amount,
    is_refund,
    send_receipt,
  }
}

export const pRequestPredictServiceCosts = (data) => {
  const {
    chefs,
    needsCleanup,
    dropoffAddress,
    clientSetUpTime,
    headquarterId: persistedOrderHqId,
    headCount: head_count,
    serviceType: service_type,
    user,
    orderType,
    setUpStyle,
    routes,
  } = data
  const dropoff_address_id = dropoffAddress && dropoffAddress.id
  const client_set_up_time = clientSetUpTime && clientSetUpTime.format()
  const userHqId = user && user.lastLoggedInHq
  const chef_ids =
    (chefs &&
      chefs
        .filter((c) => !c.chefNote || c.chefNote.hungryDeliveryRequired)
        .map((c) => c.id)) ||
    []
  const headquarterId = persistedOrderHqId || userHqId // use saved order's hq if it exists otherwise users last logged in hq

  // checks presence of supply and service cost required attributes
  if (
    !headquarterId ||
    !dropoff_address_id ||
    !client_set_up_time ||
    !head_count ||
    chef_ids.length == 0 ||
    !service_type ||
    !orderType ||
    !setUpStyle
  ) {
    return false
  }

  return {
    has_cleanup: !!needsCleanup,
    dropoff_address_id,
    client_set_up_time,
    headquarter_id: headquarterId,
    chef_ids,
    service_type,
    head_count,
    routes: snakeCaseify(routes),
  }
}

export const pResponseCateringOrder = (order) => {
  return order.chefs.map((chef) => {
    let isAccepted = -1,
      declineReason = '',
      cnStatus = false
    const status = order.chef_statuses && order.chef_statuses[chef.id]
    if (status) {
      isAccepted = status.is_accepted
      declineReason = status.decline_reason
      cnStatus = status.cn_status
    }

    return {
      id: order.id,
      orderNumber: order.order_number,
      date: order.client_set_up_time,
      chef: {
        id: chef.id,
        name: `${chef.first_name} ${chef.last_name}`,
        phone: chef.phone_number,
        email: chef.email,
      },
      isAccepted,
      declineReason,
      cnStatus,
      mealType: 'Catering',
    }
  })
}

export const pRequestProposalFromDinerProfile = (hqLocaleMap, params) => {
  const out = {
    recalc_tax_rates: true,
  }
  const loc = hqLocaleMap[params.dinerProfile.hqId]
  const time = Moment(params.date).tz(LegacyLocalesToStandardLocales[loc])
  const hour = Math.floor(params.dinerProfile.eventTime / 100)
  const minute = params.dinerProfile.eventTime % 100
  const clientSetUpTime = time.set({ hour, minute })
  out.proposal = {
    account_executive_id: params.accountExecutiveId,
    account_id: params.dinerProfile.accountId,
    client_set_up_time: clientSetUpTime,
    client_arrival_time: clientSetUpTime,
    contact_id: params.dinerProfile.contactId,
    dropoff_address_id: params.dinerProfile.addressId,
    diner_profile_id: params.dinerProfile.id,
    head_count: params.dinerProfile.headcount,
    headquarter_id: params.dinerProfile.hqId,
    invoice_contact_id: params.dinerProfile.contactId,
    order_type: params.dinerProfile.mealType,
    receipt_contact_id: params.dinerProfile.contactId,
    serving_utensil_sets: 1,
    services_instructions: params.serviceNotes || '',
    set_up_instructions: params.setUpInstructions || '',
    predicted_service_costs: '0.00',
    subtotal: '0.00',
    tax: '0.00',
    tip: '0.00',
  }

  if (params.copyParams) {
    const { copyParams } = params
    if (copyParams.accountSettings) {
      out.proposal.account_settings = snakeCaseify(copyParams.accountSettings)
    }
    if (copyParams.dinerProfileSettings) {
      out.proposal.diner_profile_settings = snakeCaseify(
        copyParams.dinerProfileSettings,
      )
    }
    if (copyParams.clientReportingProgramId) {
      out.proposal.client_reporting_program_id =
        copyParams.clientReportingProgramId
    }
    out.proposal.client_do_not_arrive_before_time =
      copyParams.clientDoNotArriveBeforeTime
        ? combineDateTime(
            clientSetUpTime,
            copyParams.clientDoNotArriveBeforeTime,
          )
        : null
    out.proposal.carbon_neutral = copyParams.carbonNeutral
    out.proposal.carbon_neutral_contribution = (
      copyParams.carbonNeutralContribution || 0
    ).toFixed(2)
    out.proposal.needs_cleanup = copyParams.needsCleanup
    out.proposal.cleanup_fee = (copyParams.cleanupFee || 0).toFixed(2)
    out.proposal.needs_staffing = copyParams.needsStaffing
    out.proposal.number_of_staff = (copyParams.numberOfStaff || 0).toFixed(2)
    out.proposal.staffing_hours = (copyParams.staffingHours || 0).toFixed(2)
    out.proposal.staffing_rate = (copyParams.staffingRate || 0).toFixed(2)
    out.proposal.staffing_fee = (copyParams.staffingFee || 0).toFixed(2)
    out.proposal.service_fee = (copyParams.serviceFee || 0).toFixed(2)
    out.proposal.delivery_fee = (copyParams.deliveryFee || 0).toFixed(2)
    out.proposal.subtotal = (copyParams.subtotal || 0).toFixed(2)
    out.proposal.tip = (copyParams.tip || 0).toFixed(2)
  }

  return out
}

export const buildAccountSettingsForBulkGenProposals = (
  accountSettings = {},
  dinerProfile = {},
) => {
  const newAccountSettings = { ...accountSettings }
  if (dinerProfile) {
    const { headcount, eventTime } = dinerProfile
    newAccountSettings.headcount = headcount
    newAccountSettings.eventTime = eventTime
    if (dinerProfile.customOrderSettings) {
      const {
        gratuity,
        gratuityType,
        needStaffAndServe,
        staffCount,
        staffHours,
        staffRate,
        deliveryAndServiceFeeCapHoliday,
        deliveryAndServiceFeeCapWeekday,
        deliveryAndServiceFeeCapWeekend,
        deliveryAndServiceFeePercentHoliday,
        deliveryAndServiceFeePercentWeekday,
        deliveryAndServiceFeePercentWeekend,
        doNotArriveBeforeTime,
      } = dinerProfile.customOrderSettings

      if (needStaffAndServe && staffCount && staffHours && staffRate) {
        newAccountSettings.needsStaffing = needStaffAndServe
        newAccountSettings.numberOfStaff = staffCount
        newAccountSettings.staffingHours = staffHours
        newAccountSettings.staffingRate = Number(staffRate)
      }

      if (deliveryAndServiceFeeCapHoliday) {
        newAccountSettings.deliveryAndServiceFeeCapHoliday = parseFloat(
          deliveryAndServiceFeeCapHoliday,
        )
      }
      if (deliveryAndServiceFeeCapWeekday) {
        newAccountSettings.deliveryAndServiceFeeCapWeekday = parseFloat(
          deliveryAndServiceFeeCapWeekday,
        )
      }
      if (deliveryAndServiceFeeCapWeekend) {
        newAccountSettings.deliveryAndServiceFeeCapWeekend = parseFloat(
          deliveryAndServiceFeeCapWeekend,
        )
      }
      if (deliveryAndServiceFeePercentHoliday) {
        newAccountSettings.deliveryAndServiceFeePercentHoliday = parseFloat(
          deliveryAndServiceFeePercentHoliday,
        )
      }
      if (deliveryAndServiceFeePercentWeekday) {
        newAccountSettings.deliveryAndServiceFeePercentWeekday = parseFloat(
          deliveryAndServiceFeePercentWeekday,
        )
      }
      if (deliveryAndServiceFeePercentWeekend) {
        newAccountSettings.deliveryAndServiceFeePercentWeekend = parseFloat(
          deliveryAndServiceFeePercentWeekend,
        )
      }
      // Gratuity settings
      if (gratuity && gratuityType) {
        newAccountSettings.gratuity = parseFloat(gratuity)
        newAccountSettings.gratuityType = gratuityType
      }

      // Do not arrive before time
      if (doNotArriveBeforeTime) {
        newAccountSettings.doNotArriveBeforeTime = doNotArriveBeforeTime
      }
    }
  }

  return newAccountSettings
}

export const pRequestProposalFromOrder = ({ params, OrderService }) => {
  const {
    calculateAll,
    calculateDeliveryFee,
    calculateStaffingFee,
    calculateSubtotal,
    calcOrderSettings,
  } = OrderService
  const { pResponseCustomOrderSettings } = presenters.Api
  const {
    accountSettings,
    date,
    dinerProfileId,
    dinerProfile,
    clientReportingProgramId,
    order,
  } = params
  const dinerProfileSettings = pResponseCustomOrderSettings(
    dinerProfile.customOrderSettings || {},
  )
  const out = {
    order_id: order.id,
    order_to_proposal: true,
    order_to_proposal_override_params: {
      account_settings: accountSettings,
      diner_profile_settings: snakeCaseify(dinerProfileSettings),
      head_count: order.headCount,
    },
  }
  if (dinerProfile.addChefNotes && dinerProfile.chefNotes) {
    out.order_to_proposal_override_params.chef_order_instructions =
      dinerProfile.chefNotes
  }

  const oldClientSetUpTimeMoment = Moment(order.clientSetUpTime)
  const newClientSetUpTime = date.set({
    hour: oldClientSetUpTimeMoment.hour(),
    minute: oldClientSetUpTimeMoment.minute(),
    second: oldClientSetUpTimeMoment.second(),
  })
  out.order_to_proposal_override_params.client_set_up_time = newClientSetUpTime

  if (dinerProfileId) {
    out.order_to_proposal_override_params.diner_profile_id = dinerProfileId
  }
  if (clientReportingProgramId) {
    out.order_to_proposal_override_params.client_reporting_program_id =
      clientReportingProgramId
  }

  const orderSettings = calcOrderSettings({
    createdAt: undefined, // new proposal should use new holiday dates
    clientSetUpTime: newClientSetUpTime,
    accountSettings,
    dinerProfileSettings,
  })

  const { doNotArriveBeforeTime } = orderSettings
  if (doNotArriveBeforeTime) {
    out.order_to_proposal_override_params.client_do_not_arrive_before_time =
      newClientSetUpTime.clone().set({
        hour: Moment(doNotArriveBeforeTime).hour(),
        minute: Moment(doNotArriveBeforeTime).minute(),
        second: Moment(doNotArriveBeforeTime).second(),
      })
  }

  order.clientSetUpTime = newClientSetUpTime
  order.didModify = true // Needed to trigger re-calc of fees
  order.tip = Number(order.tip)

  const { tip, defaultTipPercent } = orderSettings
  if (tip) {
    out.order_to_proposal_override_params.tip = tip
    order.tip = tip
  } else if (defaultTipPercent) {
    const subtotal = calculateSubtotal(order)
    const tip = roundMoneyUp((subtotal * defaultTipPercent) / 100)
    out.order_to_proposal_override_params.tip = tip
    order.tip = tip
  }

  const {
    deliveryFeeLimit,
    deliveryFeePercent,
    needsStaffing,
    numberOfStaff,
    staffingHours,
    staffingRate,
  } = orderSettings
  const { subtotal, serviceFee, cleanupFee } = calculateAll({
    ...order,
    numberOfStaff,
    staffingHours,
    staffingRate,
    needsStaffing,
    deliveryFeeLimit,
    deliveryFeePercent,
  })
  if (cleanupFee > 0) {
    out.order_to_proposal_override_params.needs_cleanup = true
  }
  out.order_to_proposal_override_params.cleanup_fee = cleanupFee
  out.order_to_proposal_override_params.service_fee = serviceFee
  out.order_to_proposal_override_params.subtotal = subtotal

  const deliveryFee = calculateDeliveryFee({
    clientSetUpTime: newClientSetUpTime,
    isDeliveryFeeOverride: order.isDeliveryFeeOverride,
    isVCX: false,
    subtotal,
    deliveryFee: order.deliveryFee,
    percent: deliveryFeePercent,
    limit: deliveryFeeLimit,
  })
  out.order_to_proposal_override_params.delivery_fee = deliveryFee

  if (needsStaffing) {
    const staffingFee = calculateStaffingFee({
      numberOfStaff,
      staffingHours,
      staffingRate,
      needsStaffing,
    })
    out.order_to_proposal_override_params.needs_staffing = needsStaffing
    out.order_to_proposal_override_params.staffing_fee = staffingFee
    out.order_to_proposal_override_params.number_of_staff = numberOfStaff
    out.order_to_proposal_override_params.staffing_hours = staffingHours
    out.order_to_proposal_override_params.staffing_rate = staffingRate
  } else {
    out.order_to_proposal_override_params.needs_staffing = false
    out.order_to_proposal_override_params.staffing_fee = 0
    out.order_to_proposal_override_params.number_of_staff = 0
    out.order_to_proposal_override_params.staffing_hours = 0
    out.order_to_proposal_override_params.staffing_rate = 0
  }

  return out
}
